import React, { createContext, useState, useContext, useEffect } from "react";
import { API_BASE_URL, SRC_BASE_URL } from "../config";

// Création du contexte d'authentification
const AuthContext = createContext();

// AuthProvider pour gérer l'authentification
export const AuthProvider = ({ children }) => {
    // Etat pour stocker les informations de l'utilisateur connecté
    const [user, setUser] = useState(null);
    // Etat pour gérer le chargement de l'authentification
    const [loading, setLoading] = useState(true);

    // Vérification de l'authentification au chargement du composant
    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const response = await fetch(`${SRC_BASE_URL}/auth/check_auth.php`, {
                credentials: "include",
            });

            const data = await response.json();
            if (data.authenticated) {
                setUser(data.user);
            }
        } catch (error) {
            console.error('Erreur de vérification de l\'authentification:', error.message);
        } finally {
            setLoading(false);
        }
    };

    // Gère la connexion d'un utilisateur
    const login = async (credentials) => {
        try {
            const response = await fetch(`${API_BASE_URL}/login.php`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(credentials),
            });

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                const data = await response.json();
                if (data.success) {
                    setUser(data.user);
                    return true;
                }
            } else {
            // Si ce n'est pas du JSON, affichez le contenu pour le débogage
            const text = await response.text();
            console.error('Réponse non-JSON reçue:', text);
            }
            return false;
        } catch (error) {
            console.error('Erreur de connexion:', error);
            return false;
        }
    };

    // Rendu du contexte d'authentification et passe les informations de l'utilisateur connecté à l'enfant
    return (
        <AuthContext.Provider value={{ user, loading, login }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);