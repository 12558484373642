import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../scss/PrivacyPolicy.scss';
import Breadcrumb from "../components/Breadcrumb";

function PrivacyPolicy() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Politique de Confidentialité", link: "/privacy_policy" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="privacy-policy">
            <h1>Politique de Confidentialité</h1>
            <section>
                <h2>Collecte des Informations</h2>
                <p>
                    Nous collectons des informations lorsque vous vous inscrivez sur notre site, 
                    lorsque vous vous connectez à votre compte, faites un achat, participez à un concours, 
                    et/ou lorsque vous vous déconnectez. Les informations recueillies incluent votre nom, 
                    votre adresse e-mail, votre numéro de téléphone et/ou votre carte de crédit.
                </p>
            </section>
            <section>
                <h2>Utilisation des Informations</h2>
                <p>
                    Toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour :
                    personnaliser votre expérience et répondre à vos besoins individuels, 
                    fournir un contenu publicitaire personnalisé, améliorer notre site web, 
                    améliorer le service client et vos besoins de prise en charge, 
                    vous contacter par e-mail, administrer un concours, une promotion, 
                    ou une enquête.
                </p>
            </section>
            <section>
                <h2>Confidentialité du Commerce en Ligne</h2>
                <p>
                    Nous sommes les seuls propriétaires des informations recueillies sur ce site. 
                    Vos informations personnelles ne seront pas vendues, échangées, transférées, 
                    ou données à une autre société pour n'importe quelle raison, sans votre consentement, 
                    en dehors de ce qui est nécessaire pour répondre à une demande et/ou une transaction, 
                    comme par exemple pour expédier une commande.
                </p>
            </section>
            <section>
                <h2>Divulgation à des Tiers</h2>
                <p>
                    Nous ne vendons, n'échangeons et ne transférons pas vos informations personnelles 
                    identifiables à des tiers. Cela ne comprend pas les tierce parties de confiance qui nous 
                    aident à exploiter notre site Web ou à mener nos affaires, tant que ces parties conviennent 
                    de garder ces informations confidentielles.
                </p>
            </section>
            <section>
                <h2>Protection des Informations</h2>
                <p>
                    Nous mettons en œuvre une variété de mesures de sécurité pour préserver la sécurité de 
                    vos informations personnelles. Nous utilisons le cryptage pour protéger les informations 
                    sensibles transmises en ligne. Nous protégeons également vos informations hors ligne. 
                    Seuls les employés qui ont besoin d'effectuer un travail spécifique (par exemple, 
                    la facturation ou le service client) ont accès aux informations personnelles identifiables. 
                    Les ordinateurs et serveurs utilisés pour stocker des informations personnelles identifiables 
                    sont conservés dans un environnement sécurisé.
                </p>
            </section>
            <section>
                <h2>Consentement</h2>
                <p>
                    En utilisant notre site, vous consentez à notre politique de confidentialité.
                </p>
            </section>
        </div>

            <Footer />
        </div>
    );
}

export default PrivacyPolicy;