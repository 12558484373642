import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../scss/LegalMentions.scss';
import Breadcrumb from "../components/Breadcrumb";

function LegalMentions() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Mentions Légales", link: "/legal_mentions" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="legal-mentions">
            <h1>Mentions Légales</h1>
            <section>
                <h2>Informations Générales</h2>
                <p>
                    Nom de la société : Cristian Nacu <br />
                    Adresse : 15 Bis Route de Saint Pouange 10430 Rosières près Troyes<br />
                    Téléphone : 03 25 75 50 12<br />
                    Email : nacucristian@gmail.com<br />
                </p>
            </section>
            <section>
                <h2>Hébergement</h2>
                <p>
                    Hébergeur : à venir<br />
                    Adresse : à venir<br />
                    Téléphone : à venir<br />
                    Email : à venir
                </p>
            </section>
            <section>
                <h2>Propriété Intellectuelle</h2>
                <p>
                    Tout le contenu du site Nacu Cristian est la propriété exclusive de l'entreprise Nacu Cristian, 
                    à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires 
                    ou auteurs.
                </p>
            </section>
            <section>
                <h2>Responsabilité</h2>
                <p>
                    Nacu Cristian ne peut être tenue responsable des dommages directs ou indirects 
                    causés au matériel de l'utilisateur lors de l'accès au site et/ou de son utilisation.
                </p>
            </section>
            <section>
                <h2>Modification des Mentions Légales</h2>
                <p>
                    L'entreprise Nacu Cristian se réserve le droit de modifier les présentes mentions à tout moment. 
                    L'utilisateur s'engage donc à les consulter régulièrement.
                </p>
            </section>
        </div>

            <Footer />
        </div>
);
}

export default LegalMentions;