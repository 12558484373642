import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../scss/Services.scss';
import Breadcrumb from "../components/Breadcrumb";
import reeducation from "../assets/reeducation.png";
import douleurs from "../assets/douleursdos.png";
import kinesport from "../assets/kinesport.jpg";
import balneotherapie from "../assets/balneo.jpg";

function Services() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Nos services", link: "/services" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="services">
                <h1 className="titleServices">Nos services</h1>
                <div className="container-cards">
                    <div className="card">
                        <div className="card-over">
                            <div className="card-recto">
                                <img src={reeducation} alt="reeducation" />
                                <h3>La rééducation post-opératoire</h3>
                            </div>
                            <div className="card-verso">
                                <div className="text">
                                    <p>La rééducation post-opératoire est une étape essentielle pour retrouver la mobilité, la force et la fonction normale après une intervention chirurgicale. Que ce soit à la suite d'une chirurgie orthopédique, comme une prothèse de hanche ou de genou, ou d'autres types d'opérations, la rééducation permet de réduire les complications, d'accélérer la guérison, et d'améliorer le résultat global.
                                    Le processus de rééducation est personnalisé selon le type de chirurgie, l'état de santé général du patient, et ses objectifs de rétablissement. Les séances de kinésithérapie peuvent inclure des exercices de renforcement musculaire, des étirements, des techniques de mobilisation articulaire, ainsi que des conseils pour gérer la douleur et éviter les tensions. Le kinésithérapeute joue un rôle clé en guidant le patient à travers chaque étape, en ajustant le programme au fur et à mesure que le patient progresse.
                                    En plus des exercices physiques, la rééducation post-opératoire inclut souvent des aspects éducatifs, où le patient apprend à effectuer des tâches quotidiennes de manière sûre et efficace, tout en évitant les mouvements qui pourraient compromettre la guérison. La rééducation est un processus graduel, qui demande patience et engagement, mais elle est cruciale pour assurer une récupération complète et pour permettre au patient de reprendre ses activités normales, voire de les améliorer.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-over">
                            <div className="card-recto">
                                <img src={douleurs} alt="douleurs musculaires" />
                                <h3>Traitement des douleurs musculaires</h3>
                            </div>
                            <div className="card-verso">
                                <div className="text">
                                    <p>Le traitement des douleurs musculaires chez un kinésithérapeute repose sur une approche globale visant à soulager la douleur, à restaurer la fonction musculaire, et à prévenir les récidives. Les douleurs musculaires peuvent être causées par divers facteurs, tels que des traumatismes, des postures incorrectes, un surmenage ou des pathologies sous-jacentes. Le rôle du kinésithérapeute est d'identifier la cause de la douleur et de proposer un plan de traitement personnalisé.
                                    Lors d'une première consultation, le kinésithérapeute réalise une évaluation complète pour comprendre l'origine de la douleur. Cela inclut un examen physique, une évaluation de la posture, et des tests fonctionnels. En fonction des résultats, il met en place un programme de traitement qui peut inclure plusieurs techniques : massages thérapeutiques pour détendre les muscles tendus, étirements pour améliorer la flexibilité, et exercices de renforcement pour stabiliser les zones affaiblies.
                                    Le traitement peut également inclure des modalités comme l'électrothérapie ou la thermothérapie (application de chaleur ou de froid) pour réduire l'inflammation et soulager la douleur. En parallèle, le kinésithérapeute éduque le patient sur les bonnes pratiques à adopter au quotidien, comme les postures à privilégier ou les mouvements à éviter, afin de limiter les contraintes sur les muscles affectés.
                                    L'objectif final du traitement est de permettre au patient de retrouver une vie active sans douleur, en renforçant les muscles, en améliorant la mobilité, et en favorisant une meilleure gestion de la douleur à long terme. Chaque traitement est adapté aux besoins spécifiques du patient, avec un suivi régulier pour évaluer les progrès et ajuster les techniques si nécessaire.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="card">
                        <div className="card-over">
                            <div className="card-recto">
                                <img src={kinesport} alt="kinésithérapie du sport" />
                                <h3>La kinésithérapie du sport</h3>
                            </div>
                            <div className="card-verso">
                                <div className="text">
                                    <p>La kinésithérapie du sport est une spécialité qui vise à prévenir, traiter et réhabiliter les blessures liées à la pratique sportive. Elle s'adresse à tous les sportifs, qu'ils soient amateurs ou professionnels, et couvre une large gamme de blessures, allant des entorses et tendinites aux déchirures musculaires et fractures.
                                    Lorsqu'un sportif se blesse, le kinésithérapeute joue un rôle essentiel dans la récupération. Après une évaluation détaillée, qui inclut une analyse des mouvements et de la posture, le kinésithérapeute établit un plan de traitement personnalisé. Ce plan peut inclure des techniques manuelles telles que des massages pour réduire les tensions et les douleurs musculaires, des exercices de rééducation pour renforcer les muscles et améliorer la stabilité articulaire, ainsi que des étirements pour restaurer la flexibilité.
                                    La kinésithérapie du sport ne se limite pas au traitement des blessures. Elle est également préventive. Le kinésithérapeute aide les athlètes à améliorer leur performance tout en minimisant le risque de blessures. Cela peut inclure des conseils sur l'entraînement, l'amélioration de la technique, et l'élaboration de programmes spécifiques pour renforcer les points faibles identifiés lors des évaluations.
                                    En outre, le kinésithérapeute accompagne le sportif tout au long de sa rééducation, en veillant à ce qu'il récupère pleinement et puisse reprendre son activité sportive dans les meilleures conditions possibles. Cette approche globale permet non seulement de traiter la blessure, mais aussi de renforcer le corps pour éviter les récidives et améliorer la performance globale.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card">
                        <div className="card-over">
                            <div className="card-recto">
                                <img src={balneotherapie} alt="balnéothérapie" />
                                <h3>La balnéothérapie</h3>
                            </div>
                            <div className="card-verso">
                                <div className="text">
                                    <p>La balnéothérapie est une approche thérapeutique qui utilise les bienfaits de l'eau pour traiter diverses affections. En kinésithérapie, elle est particulièrement prisée pour sa capacité à soulager la douleur, à favoriser la rééducation et à améliorer le bien-être général des patients. L'eau, par ses propriétés naturelles, offre un environnement idéal pour la réhabilitation, notamment grâce à sa capacité à réduire le poids du corps, ce qui diminue la pression sur les articulations et les muscles.
                                    Lors d'une séance de balnéothérapie, le patient est immergé dans une piscine spécialement conçue pour des exercices thérapeutiques. La température de l'eau, généralement chaude, aide à détendre les muscles et à améliorer la circulation sanguine, ce qui favorise la guérison des tissus endommagés. Le kinésithérapeute guide le patient à travers une série d'exercices adaptés à ses besoins spécifiques, qu'il s'agisse de rééducation post-opératoire, de gestion de douleurs chroniques, ou de récupération après une blessure.
                                    L'un des principaux avantages de la balnéothérapie est la réduction de la douleur et de l'inflammation. Les mouvements dans l'eau sont plus doux et moins contraignants, ce qui permet au patient de réaliser des exercices qu'il ne pourrait pas effectuer sur terre ferme. Cela aide non seulement à renforcer les muscles, mais aussi à améliorer la mobilité et la flexibilité, sans provoquer de stress excessif sur le corps.
                                    En résumé, la balnéothérapie en kinésithérapie est une méthode efficace pour traiter un large éventail de conditions, tout en offrant un environnement sûr et apaisant pour la rééducation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Services;