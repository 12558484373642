import React from "react";
import "../scss/Contact.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import phone from "../assets/phone.svg";
import address from "../assets/map.svg";
import opening from "../assets/opening.svg";
import BtnRdv from "../components/BtnRdv";
import ContactForm from "../components/ContactForm";
import Breadcrumb from "../components/Breadcrumb";
import Map from "../components/Map";

const PhoneNumber = ({ number }) => {
    return <a href={`tel:${number}`}>{number}</a>;
};

function Contact() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Contactez-nous !", link: "/contact" },
    ]
    
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <h1 className="titleContact">Contactez-nous !</h1>
            <div className="contact_page">  
                <div className="contactANDform">
                    <div className="contact">
                        <h2>Où nous trouver ?</h2>
                        <div className="infos_contact">
                            <div className="phone">
                                <img src={phone} alt="icone du téléphone" />
                                <h4>Appelez-nous : </h4>
                                <PhoneNumber number="03 25 75 50 12" />
                            </div>

                            <div className="address">
                                <img src={address} alt="icone de l'adresse" />
                                <h4>Adresse : </h4>
                                <p>15 Bis route de Saint Pouange 10430 Rosières près Troyes</p>
                            </div>

                            <div className="opening">
                                <img src={opening} alt="icone d'ouverture" />
                                <h4>Horaires : </h4>
                                <p>Du lundi au vendredi de 9h à 19h</p>
                            </div>
                            <BtnRdv />
                        </div>
                    </div>
                    <div className="formulaire">
                        <h2>Envoyez-nous un message !</h2>
                        < ContactForm />
                    </div>
                </div>
            </div>

            <Map />
                
            <Footer />
        </div>
    );
}

export default Contact;