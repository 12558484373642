import React, { createContext, useState, useEffect, useCallback } from "react";
import { API_BASE_URL } from "../config";

// Création du contexte
const UserContext = createContext();

// Exportation du provider qui va permettre d'utiliser le contexte
export const UserProvider = ({ children }) => {
    // Etat pour stocker les informations de l'utilisateur
    const [user, setUser] = useState(null);
    // Etat pour gérer le chargement des données
    const [loading, setLoading] = useState(true);

    // Fonction pour vérifier la session de l'utilisateur
    const checkUserSession = useCallback(async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/checkSession.php`, {
                method: "GET",
                credentials: "include",
            });
            const data = await response.json();
            if (data.success && data.user) {
                setUser(data.user);
            } else {
                setUser(null);
            }
        } catch (error) {
            console.error('Erreur lors de la vérification de la session:', error);
            setUser(null);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        checkUserSession();
    }, [checkUserSession]);

    // Gère la déconnexion d'un utilisateur
    const logout = async () => {
        try {
            await fetch(`${API_BASE_URL}/logout.php`, {
                method: "POST",
                credentials: "include",
            });
            setUser(null);
            // Rediriger vers la page de connexion ou la page d'accueil
            window.location.href = '/login';
        } catch (error) {
            console.error('Erreur lors de la déconnexion:', error);
        }
    };

    // Fonction pour vérifier l'inactivité
    useEffect(() => {
        let inactivityTimer;

        const resetInactivityTimer = () => {
            clearTimeout(inactivityTimer);
            inactivityTimer = setTimeout(() => {
                logout();
            }, 30 * 60 * 1000); // 30 minutes
        };

        // Réinitialiser le timer à chaque activité
        window.addEventListener('mousemove', resetInactivityTimer);
        window.addEventListener('keydown', resetInactivityTimer);

        // Initialiser le timer
        resetInactivityTimer();

        return () => {
            clearTimeout(inactivityTimer);
            window.removeEventListener('mousemove', resetInactivityTimer);
            window.removeEventListener('keydown', resetInactivityTimer);
        };
    }, []);

    // Récuperation des informations de l'utilisateur
    useEffect(() => {
        fetch(`${API_BASE_URL}/infosUser.php`, {
            method: "GET",
            credentials: "include",
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erreur lors de la récupération des informations utilisateur');
            }
            return response.json();
        })
        .then(data => {
            console.log("Données reçues du backend :", data);
            if (data.success) {
                setUser(data.user);
            } else {
                setUser(null);
            }
        })
        .catch(error => {
            console.error('Erreur lors de la récupération des informations utilisateur:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    // Fournit le contexte de l'utilisateur aux composants enfants
    return (
        <UserContext.Provider value={{ user, setUser, loading, logout, checkUserSession }}>
            {children}
        </UserContext.Provider>
    );
};

// Export explicite 
export { UserContext };

// export default UserContext;