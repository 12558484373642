import React, { useState, useEffect } from 'react';

// Composant RandomImage pour afficher une image aleatoire
function RandomImage({ width = 800, height = 400 }) {
    const [imageUrl, setImageUrl] = useState('');

    // Effet pour générer une image aleatoire à chaque rechargement de la page
    useEffect(() => {
        const randomImageUrl = `https://picsum.photos/${width}/${height}?random=${Math.floor(Math.random() * 1000)}`;
        setImageUrl(randomImageUrl);
    }, [width, height]);

    return (
        <div className="random-image">
            <img src={imageUrl} alt="Random blog placeholder" width={width} height={height} />
        </div>
    );
}

export default RandomImage;