import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import RandomImage from "../components/RandomImage";
import '../scss/Blog.scss';
import Breadcrumb from "../components/Breadcrumb";

function Blog() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Blog", link: "/blog" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="blog">
            <h1>Notre Blog</h1>
            <p>Cette page est en cours de construction. Revenez bientôt pour de nouveaux articles sur la santé et le bien être !</p>
            <RandomImage width={800} height={400} />
        </div>
            <Footer />
        </div>
    );
}

export default Blog;