import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../scss/Faq.scss';
import Breadcrumb from "../components/Breadcrumb";


function FAQ() {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "Foire Aux Questions", link: "/faq" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>
            <div className="faq">
                <h1>Foire Aux Questions (FAQ)</h1>
                <div className="container-sections">
                    <section>
                        <h2>Généralités</h2>
                        <div className="question">
                            <h3>Qu'est-ce que la kinésithérapie ?</h3>
                            <p>
                                La kinésithérapie est une discipline de la santé qui consiste à traiter les dysfonctionnements 
                                physiques à travers des techniques manuelles, des exercices thérapeutiques, et l'utilisation 
                                d'équipements spécifiques. Elle aide à améliorer la mobilité, soulager la douleur, et favoriser 
                                la récupération après une blessure ou une intervention chirurgicale.
                            </p>
                        </div>
                        <div className="question">
                                <h3>Quand dois-je consulter un kinésithérapeute ?</h3>
                                <p>
                                    Vous devriez consulter un kinésithérapeute si vous souffrez de douleurs persistantes, 
                                    de limitations de mouvement, ou si vous avez besoin de rééducation après une blessure ou une opération. 
                                    Un kinésithérapeute peut également vous aider à améliorer votre posture ou à gérer des conditions 
                                    chroniques comme l'arthrose.
                                </p>
                        </div>
                    </section>

                    <section>
                        <h2>Consultation</h2>
                        <div className="question">
                            <h3>Comment se déroule une séance de kinésithérapie ?</h3>
                            <p>
                                Une séance de kinésithérapie commence généralement par une évaluation de votre état physique 
                                et de vos besoins spécifiques. Le kinésithérapeute peut ensuite utiliser différentes techniques 
                                de traitement, y compris des massages, des mobilisations articulaires, des exercices thérapeutiques, 
                                et des conseils sur la posture ou les activités quotidiennes. La durée d'une séance est généralement 
                                de 30 à 60 minutes.
                            </p>
                        </div>
                        <div className="question">
                            <h3>Dois-je avoir une ordonnance pour consulter un kinésithérapeute ?</h3>
                            <p>
                                Dans la plupart des cas, une ordonnance est nécessaire pour que les séances de kinésithérapie 
                                soient remboursées par l'assurance maladie. Cependant, vous pouvez consulter un kinésithérapeute 
                                sans ordonnance, mais les frais seront entièrement à votre charge.
                            </p>
                        </div>
                        <div className="question">
                            <h3>Combien de temps dure le traitement ?</h3>
                            <p>
                                La durée du traitement varie en fonction de la gravité de votre condition et de votre réponse 
                                aux soins. Certains patients peuvent nécessiter quelques séances, tandis que d'autres peuvent 
                                avoir besoin de plusieurs semaines ou mois de traitement.
                            </p>
                        </div>
                    </section>

                    <section>
                        <h2>Tarifs et Remboursement</h2>
                        <div className="question">
                            <h3>Quels sont les tarifs pour une séance de kinésithérapie ?</h3>
                            <p>
                                Les tarifs des séances de kinésithérapie varient en fonction du type de traitement et de la 
                                durée de la séance. En France, les tarifs sont généralement réglementés, mais ils peuvent varier 
                                pour des soins non conventionnés ou des consultations à domicile.
                            </p>
                        </div>
                        <div className="question">
                            <h3>Les séances sont-elles remboursées par la sécurité sociale ?</h3>
                            <p>
                                Oui, les séances de kinésithérapie sont remboursées par la sécurité sociale à hauteur de 60 %, 
                                sur la base du tarif conventionné. Votre mutuelle peut compléter le remboursement. 
                                Pour bénéficier du remboursement, vous devez avoir une ordonnance médicale.
                            </p>
                        </div>
                        <div className="question">
                            <h3>Acceptez-vous les paiements par carte bancaire ?</h3>
                            <p>
                                Oui, nous acceptons les paiements par carte bancaire, en espèces, et par chèque. 
                                Merci de vous assurer d'avoir les moyens de paiement nécessaires lors de votre visite.
                            </p>
                        </div>
                    </section>

                    <section>
                        <h2>Autres Questions</h2>
                        <div className="question">
                            <h3>Quels sont vos horaires d'ouverture ?</h3>
                            <p>
                                Nous sommes ouverts du lundi au vendredi, de 9h00 à 19h00. Nous proposons également 
                                des consultations le samedi matin sur rendez-vous. Pour prendre un rendez-vous, veuillez 
                                nous contacter par téléphone ou via notre site web.
                            </p>
                        </div>
                        <div className="question">
                            <h3>Proposez-vous des séances à domicile ?</h3>
                            <p>
                                Oui, nous offrons des séances à domicile pour les patients qui ne peuvent pas se déplacer. 
                                Les séances à domicile nécessitent une ordonnance médicale précisant cette nécessité.
                            </p>
                        </div>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default FAQ;