import React from "react";
import "../scss/About.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Breadcrumb from "../components/Breadcrumb";
import piscine1 from "../assets/piscineBalneo1.jpg";
import piscine2 from "../assets/piscineBalneo2.jpg";
import piscine3 from "../assets/piscineBalneo1.jpg";
import piscinevuedouche from "../assets/piscineVueDouches.jpg";
import BtnRdv from "../components/BtnRdv";
import { Link } from "react-router-dom";

const About = () => {

    const paths = [
        { name: "Accueil", link: "/" },
        { name: "A propos", link: "/about" },
    ]
    return (
        <div>
            < Header />
            <div className="fil_ariane">
              <Breadcrumb paths={paths}/>  
            </div>

            <div className="about"> 
                <h1>A propos de notre cabinet</h1>
                
                <p className="about_p">Bienvenue sur le site de notre cabinet de kinésithérapie situé au cœur de l’Aube. Spécialisés en rééducation post-opératoire, traitement des douleurs, kinésithérapie du sport et balnéothérapie, nous mettons notre expertise au service de votre bien-être et de votre santé physique. Notre équipe, composée de professionnels qualifiés et passionnés, vous accompagne tout au long de votre parcours de soins, en adaptant nos pratiques à vos besoins spécifiques.
                Nous prenons en charge une large gamme de pathologies, qu’il s’agisse de douleurs chroniques, de troubles musculosquelettiques, ou de rééducation post-opératoire. Notre approche globale de la kinésithérapie repose sur des techniques manuelles précises, associées à des technologies de pointe pour favoriser une récupération optimale.
                Chez nous, chaque patient est unique. Nous privilégions une approche personnalisée pour vous offrir des soins sur mesure et un suivi attentif. Que vous veniez pour soulager des douleurs, améliorer votre mobilité ou optimiser vos performances physiques, notre cabinet est à votre disposition pour vous aider à atteindre vos objectifs de santé.</p>

                <BtnRdv />
                
                <div className="slider-container">
                    <div className="slider">
                        <img src={piscine1} alt="piscine balneo" />
                        <img src={piscine2} alt="piscine balneo" />
                        <img src={piscine3} alt="piscine balneo" />
                        <img src={piscinevuedouche} alt="piscine balneo" />
                        <img src={piscine1} alt="piscine balneo" />
                    </div>
                </div>

                <h4>Nos valeurs</h4>
                <ul>
                    <li><span>Écoute et bienveillance :</span> Nous plaçons le patient au centre de notre pratique en prenant le temps de comprendre vos attentes.</li>
                    <li><span>Professionnalisme :</span> Nos praticiens sont formés aux techniques les plus récentes et suivent régulièrement des formations pour enrichir leur savoir-faire.</li>
                    <li><span>Innovation :</span> Nous utilisons des équipements modernes pour améliorer l’efficacité de nos soins.</li>
                </ul>
                <h4>N’hésitez pas à nous contacter pour prendre rendez-vous ou obtenir plus d’informations sur nos prestations.</h4>

                <Link to="/contact">
                    <button className="BtnContact">Contactez-nous</button>
                </Link>

            </div>


            <Footer />

        </div>
    );
};

export default About;