import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Login from './pages/Login';
import Register from './pages/Register';
import Appointment from './pages/Appointment';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import Home from './pages/Home';
import LegalMentions from './pages/LegalMentions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Services from './pages/Services';
import './App.scss';
import NotFound from './components/NotFound';
import Cookie from './components/Cookie';
import { AuthProvider } from './hooks/AuthContext';
import { UserProvider } from './hooks/UserContext';

function App() {
  return (
    <AuthProvider>
      <UserProvider>
        <Router>
          <Cookie/>
          <Routes>
            <Route exact path="/about" element={<About/>} />
            <Route exact path="/services" element={<Services/>} />
            <Route exact path="/blog" element={<Blog/>} />
            <Route exact path="/contact" element={<Contact/>} />
            <Route exact path="/faq" element={<Faq/>} />
            <Route exact path="/login" element={<Login/>} />
            <Route exact path="/register" element={<Register/>} />
            <Route exact path="/appointment" element={<Appointment/>} />
            <Route exact path="/legal_mentions" element={<LegalMentions/>} />
            <Route exact path="/privacy_policy" element={<PrivacyPolicy/>} />
            <Route path="/" element={<Home/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
}

export default App;