import React, { useState } from 'react';
import '../scss/ContactForm.scss';
import { API_BASE_URL } from '../config';

/** Composant formulaire de contact */

const ContactForm = () => {
  // État pour stocker les données du formulaire
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  // Gestion des changements dans le formulaire
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Envoi des données du formulaire à l'API
      const response = await fetch(`${API_BASE_URL}/contact.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      // Gestion de la réponse 
      if (response.ok) {
        alert('Message envoyé avec succès !');
        // Reset du formulaire
        setFormData({name: '', email: '', subject: '', message: ''});
      } else {
        alert('Échec de l\'envoi du message. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Une erreur est survenue. Veuillez réessayer.');
    }
  };

  // Rendu du formulaire
  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        name="name"
        placeholder="Votre Nom"
        value={formData.name}
        onChange={handleChange}
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Votre Email"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <input
        type="text"
        name="subject"
        placeholder="Sujet"
        value={formData.subject}
        onChange={handleChange}
        required
      />
      <textarea
        name="message"
        placeholder="Votre Message"
        value={formData.message}
        onChange={handleChange}
        required
      />
      <button type="submit">Envoyer</button>
    </form>
  );
};

export default ContactForm;