// Importation des dépendances nécessaires
import React from 'react';
import { Link } from 'react-router-dom';
// Importation du style de Footer et les assets
import '../scss/Footer.scss';
import logo from '../assets/logoKine.png';
import phone from '../assets/phone.svg';
import address from '../assets/map.svg';
import opening from '../assets/opening.svg';
// Importation du composant BtnRdv
import BtnRdv from './BtnRdv';

/** Composant PhoneNumber pour rendre le numéro de téléphone cliquable et fonctionnel */
const PhoneNumber = ({ number }) => {
    return <a href={`tel:${number}`}>{number}</a>;
};

/** Composant Footer qui affiche le pied de page du site */
function Footer() {
    return (
        <div className="footer">
            <div className="footer-container">
                {/* Logo cliquable pour rediriger vers la page d'accueil */}
                <Link to={"/"}>
                <img src={logo} className='logo' alt="logo du cabinet de kinésithérapie" />
                </Link>

                {/* Informations de contact et horaires */}
                <div className="infos_footer">
                    <div className="phone">
                        <img src={phone} alt="icone du téléphone" />
                        <h4>Appellez-nous : </h4>
                        <PhoneNumber number="03 25 75 50 12" />
                    </div>

                    <div className="address">
                        <img src={address} alt="icone de l'adresse" />
                        <h4>Adresse : </h4>
                        <p>15 Bis route de Saint Pouange 10430 Rosières près Troyes</p>
                    </div>

                    <div className="opening">
                        <img src={opening} alt="icone d'ouverture" />
                        <h4>Horaires : </h4>
                        <p>Du lundi au vendredi de 9h à 19h</p>
                    </div>
                </div>

                <BtnRdv />

                {/* Liens vers les autres pages du site */}
                <div className="mentions">
                    <Link to="/faq">FAQ</Link>
                    <p> - </p>
                    <Link to="/legal_mentions">Mentions Légales</Link>
                    <p> - </p>
                    <Link to="/privacy_policy">Politique de confidentialité</Link>
                </div>
            </div>
        </div>
    );
}

export default Footer;