import React from "react";
import { Link } from "react-router-dom";
import "../scss/Breadcrumb.scss";

/** Composant qui affiche le fil d’ariane (breadcrumb) */
function Breadcrumb({ paths }) {

    return (
    <nav aria-label="breadcrumb">
      <ul className="breadcrumb">
        {paths.map((path, index) => (
          <li key={index} className={`breadcrumb-item ${index === paths.length - 1 ? 'active' : ''}`}>
            {index === paths.length - 1 ? (
              path.name
            ) : (
              <Link to={path.link}>{path.name}</Link>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumb;