import React from "react";
import "../scss/Home.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import kine from "../assets/portraitKine.jpg";
import BtnRdv from '../components/BtnRdv';
import { Link } from "react-router-dom";

const Home = () => {
   
    return (
        <div>
            <Header />
            <div className="home-container">
                <div className="home">
                    <h1>Bienvenue sur le site destiné au bien-être et à la santé</h1>
                    <BtnRdv />  
                </div>

                <div className="presentation">
                    <div className="text">
                        <h2>Qui suis-je ?</h2>
                        <p>Je m'appelle Cristian Nacu, je suis kinésithérapeute professionnel, spécialisé dans le traitement des douleurs musculosquelettiques, la rééducation post-opératoire et les soins pour les personnes souffrant de maladies chroniques. Mon cabinet est situé au cœur de Rosières près Troyes dans l’Aube, j'accueille des patients de tous âges et de tous horizons. Je m'efforce de fournir des soins personnalisés et de haute qualité, en utilisant des techniques de thérapie manuelle, d'exercice thérapeutique, de balnéothérapie et des technologies modernes pour aider mes patients à retrouver leur mobilité et leur bien-être.
                        </p>
                    </div>
                    <img src={kine} alt="Portrait du kine" />
                </div>

                <div className="servicesHome">
                    <h2>Nos services</h2>
                    <div className="services-container">
                        <Link to ="/services">
                            <div className="post-operatoire">
                                <h3>La rééducation post-opératoire</h3>
                                <p>La rééducation post-opératoire restaure la mobilité et la fonction après une chirurgie, grâce à un programme personnalisé de kinésithérapie et d'exercices adaptés.</p>    
                            </div>
                        </Link>

                        <Link to ="/services">
                            <div className="traitement-douleur">
                                <h3>Le traitement des douleurs musculaires</h3>
                                <p>Le kinésithérapeute soulage les douleurs musculaires via massages, étirements, et exercices, visant à restaurer la fonction musculaire et prévenir les récidives.</p>  
                            </div>
                        </Link>

                        <Link to ="/services">
                            <div className="kine-sports">
                                <h3>La kinésithérapie du sport</h3>
                                <p>La kinésithérapie du sport prévient, traite et réhabilite les blessures sportives, aidant les athlètes à récupérer et à améliorer leur performance en toute sécurité.</p>
                            </div>
                        </Link>

                        <Link to ="/services">
                            <div className="balneotherapie">
                                <h3>La balnéothérapie et ses bienfaits</h3>
                                <p>La balnéothérapie utilise les bienfaits de l'eau pour soulager la douleur, améliorer la mobilité et favoriser la rééducation dans un cadre apaisant et sûr.</p>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="bienvenueHome">
                    <h2>Bienvenue dans mon cabinet de kinésithérapie, un espace de bienveillance où votre bien-être est ma priorité.</h2>
                    <div className="bienvenue-container">
                    <p>Ici, nous nous engageons à vous offrir des soins personnalisés, centrés sur vos besoins spécifiques, avec bienveillance et professionnalisme. Chaque patient est unique, et nous mettons un point d'honneur à vous accompagner tout au long de votre parcours vers le soulagement et la guérison.

                    Notre approche repose sur une écoute attentive et une compréhension profonde de vos douleurs et inconforts. Que vous veniez pour des douleurs chroniques, des blessures sportives, ou une rééducation post-opératoire, nous sommes là pour vous aider à retrouver votre mobilité et à améliorer votre qualité de vie. Nous utilisons des techniques de soin modernes et efficaces, combinées à des méthodes douces, pour vous offrir un traitement qui respecte votre corps et vos limites.

                    Au-delà des soins, nous souhaitons que vous vous sentiez apaisé et soutenu dans notre cabinet. Nous avons créé un environnement serein, propice à la détente et à la récupération, où chaque détail est pensé pour votre confort. Notre objectif est de vous voir repartir avec un sentiment de soulagement et de mieux-être, prêt à affronter vos activités quotidiennes avec plus d'aisance et de confiance.

                    Merci de nous faire confiance pour prendre soin de vous. Nous sommes impatients de vous accompagner vers une meilleure santé et un plus grand bien-être.</p>
                    </div>
                </div>
                <div className="img-cover"></div>

            </div>

            <Footer />
            
        </div>
    );
};

export default Home;