import React, { useState, useEffect, useCallback } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../scss/Register.scss";
import { API_BASE_URL } from "../config";

function RegisterForm () {

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [lastname, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);

  const validateEmail = (email) => {
    const regex = /^[\w\-.]+@([\w-]+.)+[\w-]{2,}$/;
    return regex.test(email);
  };

  const validatePassword = (password) => {
    let errors = {};
    errors.length = password.length >= 8;
    errors.uppercase = /[A-Z]/.test(password);
    errors.lowercase = /[a-z]/.test(password);
    errors.number = /\d/.test(password);
    errors.special = /[!@#$%^&*(),.?":{}|<>+=]/.test(password);
    return errors;
  };

  const validatePhoneNumber = (phone_number) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(phone_number);
  };

  const validateForm = useCallback(() => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = Object.values(validatePassword(password)).every(Boolean);
    const doPasswordsMatch = password === confirmPassword;
    const isLastNameValid = lastname.trim().length > 0;
    const isFirstNameValid = firstname.trim().length > 0;
    const isPhoneNumberValid = validatePhoneNumber(phone_number);
    const isCaptchaValid = captchaValue !== null;

    setIsSubmitDisabled(!(isEmailValid && isPasswordValid && doPasswordsMatch && isLastNameValid && isFirstNameValid && isPhoneNumberValid && isCaptchaValid));
  }, [email, lastname, firstname, phone_number, password, confirmPassword, captchaValue]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const handleChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitDisabled) return;
  
    try {
      setLoading(true);
      setErrors({});
  
      const response = await fetch(`${API_BASE_URL}/register.php`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password, lastname, firstname, phone_number, captcha: captchaValue }),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || "Une erreur est survenue");
      }
  
      setSuccess(data.message || "Inscription réussie, veuillez vous connecter");
  
      setTimeout(() => {
        navigate("/login");
      }, 2000);
  
    } catch (error) {
      console.error("Erreur lors de l'inscription:", error);
      setErrors({ message: error.message || "Une erreur inattendue s'est produite" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <div className="form_register">
        <h3>Inscrivez-vous, c'est gratuit !</h3>

        <form onSubmit={handleSubmit} className="form" action="">
        
          <div className="input_lastname">
            <input
            type="text"
            value={lastname}
            onChange={handleChange(setLastName)}
            placeholder="Votre nom"
            required
            maxLength={50}
            />
          </div>
          <div className="input_firstname">
            <input
              type="text"
              value={firstname}
              onChange={handleChange(setFirstName)}
              placeholder="Votre Prénom"
              required
              maxLength={50}
            />
          </div>
          <div className="input_phone">
            <input
              type="tel"
              value={phone_number}
              onChange={handleChange(setPhoneNumber)}
              placeholder="Votre numéro de téléphone"
              required
            />
            <div className="error_phone">
              {!validatePhoneNumber(phone_number) && phone_number.length > 0 && "Ce numéro de téléphone n'est pas valide"}
            </div>
          </div>
          
          <div className="input_email">
            <input
              type="text"
              value={email}
              onChange={handleChange(setEmail)}
              placeholder="Votre email"
              required
            />
            <div className="error_email">
              {!validateEmail(email) && email.length > 0 && "Cet email n'est pas valide"}
            </div>
          </div>

          <div className="input_pass">
            <input
              type="password"
              value={password}
              onChange={handleChange(setPassword)}
              placeholder="Mot de passe"
              required
            />
            <div className="error_pass">
              {Object.entries(validatePassword(password)).map(([key, value]) => (
                <div key={key} style={{ color: value ? "green" : "inherit" }}>
                  {getErrorText(key)}
                </div>
              ))}
            </div>
          </div>

          <div className="input_pass">
            <input
              type="password"
              value={confirmPassword}
              onChange={handleChange(setConfirmPassword)}
              placeholder="Confirmer mot de passe"
              required
            />
            <div className="error_confirmpass">
              {password !== confirmPassword && "Les mots de passe ne sont pas identiques"}
            </div>
          </div>

          <ReCAPTCHA
            sitekey="6LdyKD4qAAAAAAK8cTNpmsiruI8l_mMHORXgWrV6"
            onChange={(value) => setCaptchaValue(value)} 
          />

          <button type="submit" disabled={isSubmitDisabled || loading}>
            {loading ? 'Inscription en cours...' : 'S\'inscrire'}
          </button>

          {success && <p style={{ color: 'green' }}>{success}</p>}
          {errors.message && <p style={{ color: 'red' }}>{errors.message}</p>}

        </form>

        <p>Vous avez déjà un compte ? <Link to="/login">Se connecter</Link></p>
      </div>
      <Footer />
    </div>
  );
}

const getErrorText = (key) => {
  const errorMessages = {
    length: "Doit faire au moins 8 caractères",
    uppercase: "Doit contenir une majuscule",
    lowercase: "Doit contenir une minuscule",
    number: "Doit contenir un chiffre",
    special: "Doit contenir un caractère spécial",
  };
  return errorMessages[key];
};

export default RegisterForm;